import {Chess} from "chess.js";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "../../shared/StyledComponents";
import {BaseApp} from "../../shared/BaseApp";
import {Button, IconButton, Tab, Tabs, TextareaAutosize, TextField, Tooltip, Typography} from "@mui/material";
import {ContentCopyOutlined} from "@mui/icons-material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;
  return value === index && <>{children}</>;
}

function DownloadView(props: { game: Chess, imageUrl: string }): ReactElement {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const fen = props.game.fen();
  const pgn = props.game.pgn({newline: "\n\n"});
  return <StyledBoxColumn style={{height: 560, gap: 0, marginTop: -12}}>
    <Tabs value={value} onChange={handleChange}>
      <Tab label="Board"/>
      <Tab label="Image"/>
    </Tabs>
    <TabPanel index={0} value={value}>
      <StyledBoxColumn style={{flexGrow: 1, marginTop: 24}}>
        <Typography variant="body2" style={{fontWeight: "bold"}}>FEN</Typography>
        <StyledBoxRow style={{alignItems: "start"}}>
          <TextField size="small" style={{flexGrow: 1}} disabled defaultValue={fen}/>
          <Tooltip title={"Copy to clipboard"}>
            <IconButton onClick={() => window.navigator.clipboard.writeText(fen)}>
              <ContentCopyOutlined/>
            </IconButton>
          </Tooltip>
        </StyledBoxRow>
        <Typography variant="body2" style={{fontWeight: "bold"}}>PGN</Typography>
        <StyledBoxRow style={{alignItems: "start"}}>
          <TextareaAutosize style={{flexGrow: 1}} minRows={6} defaultValue={pgn} disabled/>
          <Tooltip title={"Copy to clipboard"}>
            <IconButton onClick={() => window.navigator.clipboard.writeText(pgn)}>
              <ContentCopyOutlined/>
            </IconButton>
          </Tooltip>
        </StyledBoxRow>
        <StyledSpan/>
        <Button variant="contained" onClick={() => {
          const element = document.createElement('a');
          element.href = "data:text/plain;charset=utf-8," + encodeURIComponent(pgn);
          element.download = "game.pgn";
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }}>Download</Button>
      </StyledBoxColumn>
    </TabPanel>
    <TabPanel index={1} value={value}>
      <img src={props.imageUrl} style={{flexGrow: 1, aspectRatio: 1}}/>
      <StyledSpan/>
      <Button variant="contained" onClick={() => {
        const element = document.createElement('a');
        element.href = props.imageUrl;
        element.setAttribute('download', "game.png");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }}>Download</Button>
    </TabPanel>
  </StyledBoxColumn>;
}

export class DownloadHelper {

  static showDialog(game: Chess, imageUrl: string): void {
    BaseApp.CONTEXT.showCustomDialog("Download", <DownloadView game={game} imageUrl={imageUrl}/>);
  }
}