import {Action} from "../../shared/types";
import {Button, TextField} from "@mui/material";
import {DW_XS, PD_XXLG} from "../../shared/dimens";
import React, {ReactElement, useState} from "react";
import {App} from "../App";
import {DIALOG_FLAG_ANIM_SLIDE, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {CustomDialogContent} from "../../shared/Dialogs";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {getMemberAuth} from "../../shared/auth";
import {
  DifficultyMetadata,
  Layout,
  Opponent,
  Room,
  ROOM_CODE_LENGTH,
  RoomGame,
  RoomGames,
  RoomJoin,
  RoomJoins,
  Rooms
} from "../types";
import {GameFragment} from "./GameFragment";
import {passkey} from "../../shared/passkey";
import {PathProps} from "../../index";

export function renderGameButton(action: Action) {
  return <Button
    style={{
      fontSize: "150%",
      fontFamily: "Gabarito, sans-serif",
      paddingLeft: PD_XXLG,
      paddingRight: PD_XXLG
    }}
    variant="contained"
    onClick={action.onClick}>{action.text}</Button>
}

const DELAY = 1;

export class GameColor {

  static readonly VALUES = new Array<GameColor>(2);
  static readonly WHITE = GameColor.VALUES[0] = new GameColor("w", "White");
  static readonly BLACK = GameColor.VALUES[1] = new GameColor("b", "Black");

  private constructor(readonly name: string, readonly text: string) {
  }

  getOther(): GameColor {
    return this.name === "w" ? GameColor.BLACK : GameColor.WHITE;
  }
}

function EnterRoomCodeView(props: { onRoomCodeEntered: (code: string) => void }): ReactElement {
  const [code, setCode] = useState<string>("");
  const enabled = code.length >= 6;
  const onKeyDown = event => {
    if (enabled && event.keyCode === 13) {
      event.preventDefault();
      props.onRoomCodeEntered(code);
    }
  };
  return <CustomDialogContent
    style={{minWidth: DW_XS, width: null}}
    title="Enter room code"
    customView={
      <StyledBoxColumn>
        <TextField
          autoFocus
          type="text"
          required
          onKeyDown={onKeyDown}
          inputProps={{
            style: {fontSize: "300%", fontWeight: "bold", textAlign: "center"},
            maxLength: ROOM_CODE_LENGTH,
          }}
          value={code}
          onChange={event => setCode(event.target.value?.trim().toUpperCase() || "")}/>
        <Button
          style={{
            fontSize: "150%",
            fontFamily: "Gabarito, sans-serif",
            paddingLeft: PD_XXLG,
            paddingRight: PD_XXLG
          }}
          disabled={code.length < ROOM_CODE_LENGTH}
          variant="contained"
          onClick={() => props.onRoomCodeEntered(code)}>
          Join room
        </Button>
      </StyledBoxColumn>
    }/>;
}

abstract class BaseGameHelper {

  protected opponent: Opponent = Opponent.FRIEND;
  protected color: GameColor;
  protected difficulty: DifficultyMetadata;

  constructor(readonly path: PathProps) {
  }

  private renderColorButton(color: GameColor) {
    return renderGameButton(new Action("Play as " + color.text, () => {
      App.CONTEXT.hideDialog();
      setTimeout(() => {
        this.color = color;
        this.onCreateGame();
      }, DELAY);
    }));
  }

  protected showChooseColorDialog() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Choose color"
        customView={
          <StyledBoxColumn>
            {GameColor.VALUES.map(color => this.renderColorButton(color))}
          </StyledBoxColumn>
        }/>
    );
  }

  protected abstract onCreateGame();

  private renderDifficultyButton(difficulty: DifficultyMetadata) {
    return renderGameButton(new Action(difficulty.text, () => {
      App.CONTEXT.hideDialog();
      setTimeout(() => this.onDifficultySelected(difficulty), DELAY);
    }));
  }

  protected showChooseDifficultyDialog() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Choose difficulty"
        customView={
          <StyledBoxColumn>
            {DifficultyMetadata.VALUES.map(difficulty => this.renderDifficultyButton(difficulty))}
          </StyledBoxColumn>
        }/>
    );
  }

  protected onDifficultySelected(difficulty: DifficultyMetadata): void {
    this.difficulty = difficulty;
    this.showChooseColorDialog();
  }
}

export class NewGameHelper extends BaseGameHelper {

  constructor(path: PathProps) {
    super(path);
  }

  createNewGame() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Choose opponent"
        customView={
          <StyledBoxColumn>
            {renderGameButton(new Action("With a friend", () => {
              App.CONTEXT.hideDialog();
              setTimeout(() => {
                this.opponent = Opponent.FRIEND;
                this.showNewGameFriend();
              }, DELAY);
            }))}
            {renderGameButton(new Action("With computer", () => {
              App.CONTEXT.hideDialog();
              setTimeout(() => {
                this.opponent = Opponent.COMPUTER;
                this.showChooseDifficultyDialog();
              }, DELAY);
            }))}
          </StyledBoxColumn>
        }/>
    );
  }

  private showNewGameFriend() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Create game"
        customView={
          <StyledBoxColumn>
            {renderGameButton(new Action("Create room code", () => {
              App.CONTEXT.hideDialog();
              setTimeout(() => this.showChooseColorDialog(), DELAY);
            }))}
            {renderGameButton(new Action("Enter room code", () => {
              App.CONTEXT.hideDialog();
              setTimeout(() => this.showEnterRoomCodeDialog(), DELAY);
            }))}
          </StyledBoxColumn>
        }/>
    );
  }

  private showEnterRoomCodeDialog() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <EnterRoomCodeView onRoomCodeEntered={code => {
        App.CONTEXT.hideDialog();
        setTimeout(() => this.joinAndShowNewRoom(code), DELAY);
      }}/>
    );
  }

  private async joinAndShowNewRoom(code: string) {
    const memberId = getMemberAuth().getMemberId();
    const room = await Rooms.getInstance().getOrLoadItem(code);
    if (!room) {
      App.CONTEXT.showToast("Room not found! Please try again.");
      return;
    }
    if (room.opponent === Opponent.COMPUTER || (room.joinedBy && room.joinedBy !== memberId)) {
      App.CONTEXT.showToast("Room is unavailable! Please try again.");
      return;
    }
    if (!room.joinedBy) {
      room.joinedBy = memberId;
      room.joinedAt = Date.now();
      room.joiner = getMemberAuth().member;
      await Rooms.getInstance().addListItem(room);
    }
    const join = await RoomJoins.getInstance().loadListItem(room.id);
    if (!join) {
      await RoomJoins.getInstance().addListItem(RoomJoin.createNew(room.id));
    }
    App.CONTEXT.showFullscreenDialog(() => <GameFragment path={this.path} initialRoom={room}/>);
  }

  protected onCreateGame() {
    this.createAndShowNewRoom();
  }

  private async createAndShowNewRoom() {
    const room = new Room(
      passkey({
        length: 6,
        type: "alphabetic_upper"
      }),
      getMemberAuth().getMemberId(),
      Date.now(),
      this.opponent,
      this.difficulty?.name)
      .updateSelf(Layout.createNew(this.color.name));
    await Rooms.getInstance().addListItem(room);
    await RoomJoins.getInstance().addListItem(RoomJoin.createNew(room.id));
    App.CONTEXT.showFullscreenDialog(() => <GameFragment path={this.path} initialRoom={room}/>);
  }
}


export class NewPageHelper extends BaseGameHelper {

  constructor(path: PathProps, private readonly room: Room) {
    super(path);
    this.difficulty = DifficultyMetadata.VALUES.find(value => value.name === room.difficulty);
    this.opponent = room.opponent;
  }

  protected onCreateGame() {
    this.updateRoomInternal();
  }

  private async updateRoomInternal() {
    let color = this.color;
    const memberId = getMemberAuth().getMemberId();
    if (this.room.creator !== memberId) {
      color = color.getOther();
    }
    await Rooms.getInstance().addListItem(this.room.clone<Room>(Room).updateSelf(Layout.createNew(color.name)));
    await RoomGames.getInstance().addListItem(RoomGame.createNew(this.room.id))
  }

  updateRoom() {
    this.showChooseColorDialog();
  }
}