import {BaseUploadFragment, BaseUploadFragmentProps, BaseUploadFragmentState} from "./BaseUploadFragment";
import {DesktopFile} from "shared/files";
import {getDownloadURL, uploadBytes} from "@firebase/storage";
import {MimeTypeMap} from "./mime_types";
import {v4 as uuid} from "uuid"
import {storageRef} from "./storage";
import {FileUploadOptions} from "./types";
import {BaseApp, ContextType} from "./BaseApp";
import {PluginApp} from "./PluginApp";
import {ProvisionedApp} from "./ProvisionedApp";

export type StoredDataFileUploadFragmentProps = BaseUploadFragmentProps & {
  mimeTypes?: string[],
  storedDataSource: string,
  storedDataPath?: string,
  fileUploadOptions?: FileUploadOptions,
}

type StoredDataFileUploadFragmentState = BaseUploadFragmentState & {
  uploadedFile?: any,
}

export class StoredDataFileUploadFragment extends BaseUploadFragment<StoredDataFileUploadFragmentProps, StoredDataFileUploadFragmentState> {

  protected getMimeTypes(): string[] {
    return this.props.mimeTypes;
  }

  protected onUploadFile(file: DesktopFile): Promise<void> {
    const FAKE_UPLOAD = true;
    if (FAKE_UPLOAD) {
      return new Promise(resolve => {
        this.setUploadedFiles([URL.createObjectURL(file.file.blob)], [file]);
        resolve();
      });
    }
    let contextScope: string = "";
    if (BaseApp.CONTEXT.contextType() === ContextType.PLUGIN) {
      contextScope = "plugins/" + (BaseApp.CONTEXT as PluginApp).getPluginId() + "/";
    } else if (BaseApp.CONTEXT.contextType() === ContextType.PROVISIONED_APP) {
      contextScope = "apps/" + (BaseApp.CONTEXT as ProvisionedApp).getProvisionedAppId() + "/";
    }
    const storedDataSource = contextScope + this.props.storedDataSource;
    const fileRef = storageRef(storedDataSource + "/" + (this.props.storedDataPath || (uuid() + "." + MimeTypeMap.getFileExtensionFromUrl(file.file.name))));
    return uploadBytes(fileRef, file.file.blob)
      .then(snapshot => getDownloadURL(snapshot.ref))
      .then(url => this.setUploadedFiles([url], [file]));
  }
}
