import {Room, RoomJoin, RoomJoins, Rooms} from "../types";
import React from "react";
import {StyledContainer, StyledListItem} from "../../shared/StyledComponents";
import {PD_LG} from "../../shared/dimens";
import {DrawOutlined} from "@mui/icons-material";
import {EmptyConfig, ListItemChange, OnListItemsListener, UserProfilePhoto} from "../../shared/types";
import {App} from "../App";
import {GameFragment} from "./GameFragment";
import {FooterTabFragment, FooterTabFragmentProps, FooterTabFragmentState} from "../../shared/FooterTabFragment";
import {getMemberAuth} from "../../shared/auth";
import {ChessBackground} from "./ChessBackground";

export type GamesFragmentProps = FooterTabFragmentProps & {}

type GamesFragmentState = FooterTabFragmentState & {
  rooms: Room[],
}

export class GamesFragment extends FooterTabFragment<GamesFragmentProps, GamesFragmentState> implements OnListItemsListener<RoomJoin> {

  private readonly memberId = getMemberAuth().getMemberId();
  private readonly loader = RoomJoins.getInstance();

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    if (forceReload) {
      await this.loader.loadListItems();
    } else {
      await this.loader.maybeLoadListItems();
    }
    const rooms: Room[] = [];
    for (const join of this.loader.getListItems()) {
      const room = await Rooms.getInstance().getOrLoadItem(join.id);
      if (room) {
        rooms.push(room);
      }
    }
    this.setState({
      rooms: rooms,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    RoomJoins.getInstance().registerObserver(this);
  }

  componentWillUnmount() {
    RoomJoins.getInstance().unregisterObserver(this);
    super.componentWillUnmount();
  }

  onItemChanged(item: RoomJoin, change: ListItemChange) {
    this.reload(true);
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      title: "No game joined",
      text: "When you join or create a game, it will show up here.",
      iconType: DrawOutlined,
    };
  }

  renderContent(): React.ReactElement {
    if (!(this.state.rooms?.length > 0)) {
      return this.renderEmpty();
    }
    return <ChessBackground>
      <StyledContainer size="sm" style={{padding: PD_LG}}>
        {this.state.rooms?.map(item => <StyledListItem
          img={UserProfilePhoto(item.creator === this.memberId ? item.joiner?.user : item.member.user)}
          title={item.id}
          onClick={() => App.CONTEXT.showFullscreenDialog(() => <GameFragment path={this.props.path}
                                                                              initialRoom={item}/>)}
        />)}
      </StyledContainer>
    </ChessBackground>;
  }
}