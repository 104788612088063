import {BestMoveResponse} from "./types";
import {Chess} from "chess.js";

export class Api {

  private static instance;

  static getInstance(): Api {
    if (!this.instance) {
      this.instance = new Api();
    }
    return this.instance;
  }

  private constructor() {
  }

  async getBestMove(game: Chess, elo?: number): Promise<BestMoveResponse> {
    let url = "https://gateway-1068401357304.us-west1.run.app/stockfish/v1/bestmove";
    if (elo && Number.isInteger(elo) && elo > 0) {
      url += "/" + elo;
    }
    url += "?fen=" + game.fen();
    let maxTries = 5;
    let waitFor = 5000;
    do {
      try {
        return await fetch(url, {signal: AbortSignal.timeout(waitFor)}).then(resp => resp.json());
      } catch (e) {
        // Continue
        waitFor = waitFor * 1.1;
      }
    } while (--maxTries > 0);
    return Promise.reject("Failed to fetch.");
  }
}
