import React, {ReactElement, useState} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "../../shared/StyledComponents";
import {BaseApp} from "../../shared/BaseApp";
import {FormGenContainer} from "../../shared/FormGenContainer";
import {CUSTOM_COLORS, CUSTOM_PIECES, Settings} from "../types";
import {Button} from "@mui/material";
import {Chessboard} from "react-chessboard";

function SettingsView(props: { settings: Settings, setSettings: (settings: Settings) => void }): ReactElement {
  const [settings_, setSettings_] = useState<Settings>(props.settings.clone());
  const customColors = CUSTOM_COLORS.findMetadata(settings_.colorsSet);
  return <StyledBoxColumn style={{height: 560, gap: 0}}>
    <div style={{marginBottom: -330, overflowY: "hidden"}}>
      <Chessboard
        arePiecesDraggable={false}
        customLightSquareStyle={{backgroundColor: customColors.light}}
        customDarkSquareStyle={{backgroundColor: customColors.dark}}
        customPieces={CUSTOM_PIECES.findMetadata(settings_.piecesSet)}
      />
    </div>
    <FormGenContainer style={{zIndex: 2000, flexGrow: 1, background: "white"}} content={settings_}
                      onContainerWillSave={() => setSettings_(settings_.clone())}/>
    <StyledBoxRow>
      <StyledSpan/>
      <Button onClick={() => BaseApp.CONTEXT.hideDialog()}>Cancel</Button>
      <Button
        onClick={() => {
          BaseApp.CONTEXT.hideDialog();
          props.setSettings(settings_);
        }}
        variant="contained">
        Save
      </Button>
    </StyledBoxRow>
  </StyledBoxColumn>;
}

export class SettingsHelper {

  static showDialog(settings: Settings, setSettings: (settings: Settings) => void): void {
    BaseApp.CONTEXT.showCustomDialog("Settings", <SettingsView settings={settings} setSettings={setSettings}/>);
  }
}