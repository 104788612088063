import {JsonObject} from "../common/json/json-object";
import {JsonProperty} from "../common/json/json-property";
import {v4 as uuid} from "uuid";
import React from "react";
import {$KTM, $KTS, BaseListItem, BaseListItemsLoader, KeyMetadatas, KeyTextStrings} from "../shared/types";
import {JSON_OBJECT} from "../shared/json/helpers";
import {getMemberAuth} from "../shared/auth";
import {Member, Members, User} from "../shared/entities";
import {DateUtil, WEEK_IN_MILLIS} from "../shared/date_util";
import {md5_uuid} from "../shared/md5";
import {FormGen} from "../shared/formgen";
import {CustomPieceFnArgs, CustomPieces} from "react-chessboard/dist/chessboard/types";
import {Chess} from "chess.js";

export const ROOM_CODE_LENGTH = 6;

export enum Piece {
  wK = "wK",
  wQ = "wQ",
  wR = "wR",
  wB = "wB",
  wN = "wN",
  wP = "wP",
  bK = "bK",
  bQ = "bQ",
  bR = "bR",
  bB = "bB",
  bN = "bN",
  bP = "bP",
}

export enum PiecesSet {
  DEFAULT = "default",
  // FANCY = "fancy",
  CHESS24 = "chess24",
  CHUNKY = "chunky",
  DILENA = "dilena",
  LEIPZIG = "leipzig",
  METRO = "metro",
  SYMBOL = "symbol",
  USCF = "uscf",
}

function createCustomPieces(set: string): CustomPieces {
  const customPieces = {};
  Object.getOwnPropertyNames(Piece).forEach(key => {
    customPieces[key] = (args: CustomPieceFnArgs) =>
      <img src={"/assets/pieces/" + set + "/" + key + ".png"}
           style={{width: args.squareWidth}}/>;
  });
  return customPieces;
}

export const CUSTOM_PIECES = new KeyMetadatas<CustomPieces>([
  $KTM(PiecesSet.DEFAULT, null),
  $KTM(PiecesSet.CHESS24, createCustomPieces("chess24")),
  $KTM(PiecesSet.CHUNKY, createCustomPieces("chunky")),
  $KTM(PiecesSet.DILENA, createCustomPieces("dilena")),
  $KTM(PiecesSet.LEIPZIG, createCustomPieces("leipzig")),
  $KTM(PiecesSet.METRO, createCustomPieces("metro")),
  $KTM(PiecesSet.SYMBOL, createCustomPieces("symbol")),
  $KTM(PiecesSet.USCF, createCustomPieces("uscf")),
]);

export const PIECES_SET = new KeyTextStrings([
  $KTS(PiecesSet.DEFAULT, "Default"),
  $KTS(PiecesSet.CHESS24, "Chess 24"),
  $KTS(PiecesSet.CHUNKY, "Chunky"),
  $KTS(PiecesSet.DILENA, "Dilena"),
  $KTS(PiecesSet.LEIPZIG, "Leipzig"),
  $KTS(PiecesSet.METRO, "Metro"),
  $KTS(PiecesSet.SYMBOL, "Symbol"),
  $KTS(PiecesSet.USCF, "USCF"),
]);

export enum ColorsSet {
  DEFAULT = "default",
  GREEN = "green",
  BLUE = "blue",
}

type CustomColors = {
  dark: string,
  light: string,
}

export const CUSTOM_COLORS = new KeyMetadatas<CustomColors>([
  $KTM(ColorsSet.DEFAULT, {light: "#F0D9B5", dark: "#B58863"}),
  $KTM(ColorsSet.GREEN, {light: "#FFFFFF", dark: "#58AC8A"}),
  $KTM(ColorsSet.BLUE, {light: "#E0E0E0", dark: "#727FA2"}),
]);

export const COLORS_SET = new KeyTextStrings([
  $KTS(ColorsSet.DEFAULT, "Default"),
  $KTS(ColorsSet.GREEN, "Green"),
  $KTS(ColorsSet.BLUE, "Blue"),
]);


@JsonObject()
export class Settings {

  @JsonProperty()
  @FormGen({name: "Pieces", type: "enum", enumValues: PIECES_SET.values, enumDefaultKey: PiecesSet.DEFAULT})
  readonly piecesSet: string;

  @JsonProperty()
  @FormGen({name: "Colors", type: "enum", enumValues: COLORS_SET.values, enumDefaultKey: ColorsSet.DEFAULT})
  readonly colorsSet: string;

  constructor(piecesSet: PiecesSet = PiecesSet.DEFAULT, colorsSet: ColorsSet = ColorsSet.DEFAULT) {
    this.piecesSet = piecesSet;
    this.colorsSet = colorsSet;
  }

  clone(): Settings {
    return JSON_OBJECT.deserializeObject(JSON_OBJECT.serializeObject(this), Settings);
  }
}

@JsonObject()
export class Layout {

  static createNew(creatorColor: string): Layout {
    return new Layout(md5_uuid(), creatorColor);
  }

  @JsonProperty()
  readonly id: string;

  @JsonProperty()
  readonly creatorColor: string;

  constructor(id: string, creatorColor: string) {
    this.id = id;
    this.creatorColor = creatorColor;
  }
}

export enum Opponent {
  FRIEND = "friend",
  COMPUTER = "computer",
}

export enum Difficulty {
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
}

function withUser(metadata: DifficultyMetadata): DifficultyMetadata {
  const id = "computer-" + metadata.name;
  const member = new Member(id, null, null);
  member.user = new User(null, `Computer (${metadata.text})`, null, "/images/" + id + ".png");
  metadata._setMember(member);
  return metadata;
}

export class DifficultyMetadata {

  static readonly VALUES = new Array<DifficultyMetadata>(3);
  static readonly EASY = DifficultyMetadata.VALUES[0] = withUser(new DifficultyMetadata(Difficulty.EASY, 900, "Easy"));
  static readonly MEDIUM = DifficultyMetadata.VALUES[1] = withUser(new DifficultyMetadata(Difficulty.MEDIUM, 1800, "Medium"));
  static readonly HARD = DifficultyMetadata.VALUES[2] = withUser(new DifficultyMetadata(Difficulty.HARD, 2700, "Hard"));

  private _member: Member;

  private constructor(readonly name: Difficulty, readonly elo: number, readonly text: string) {
  }

  member(): Member {
    return this._member;
  }

  _setMember(member: Member): void {
    this._member = member;
  }
}

@JsonObject()
export class Room extends BaseListItem {

  joiner: Member;

  difficultyMetadata: DifficultyMetadata;

  @JsonProperty()
  readonly opponent: Opponent;

  @JsonProperty()
  readonly difficulty: Difficulty;

  @JsonProperty()
  joinedBy: string;

  @JsonProperty()
  joinedAt: number;

  @JsonProperty()
  layout: Layout;

  @JsonProperty()
  resignedBy: "w" | "b";

  @JsonProperty()
  completedAt: number;

  @JsonProperty()
  abandonedAt: number;

  @JsonProperty()
  settings: Settings = new Settings();

  constructor(id: string, creator: string, created: number, opponent: Opponent, difficulty?: Difficulty) {
    super(id, creator, created);
    this.opponent = opponent;
    this.difficulty = difficulty;
  }

  amIPlayingWhite(): boolean {
    return (this.creator === getMemberAuth().getMemberId() && this.layout.creatorColor === "w")
      || (this.creator !== getMemberAuth().getMemberId() && this.layout.creatorColor === "b");
  }

  updateSelf(layout?: Layout, settings?: Settings): Room {
    if (layout) {
      this.layout = layout;
    }
    if (settings) {
      this.settings = settings;
    }
    this.resignedBy = undefined;
    this.completedAt = undefined;
    this.abandonedAt = undefined;
    return this;
  }

  async onAfterItemDeserialized(): Promise<void> {
    if (this.joinedBy) {
      this.joiner = await Members.getInstance().getOrLoadMember(this.joinedBy);
    }
    if (this.difficulty) {
      this.difficultyMetadata = DifficultyMetadata.VALUES.find(value => value.name === this.difficulty);
    }
  }

  isCompleted(): boolean {
    return this.completedAt > 0;
  }

  isPlayable(): boolean {
    return Date.now() < this.created + WEEK_IN_MILLIS && Boolean(this.joinedAt) && Boolean(this.joinedBy) && !this.completedAt && !this.abandonedAt;
  }
}

export class Rooms extends BaseListItemsLoader<Room> {

  private static instance: Rooms;

  static getInstance(): Rooms {
    if (!this.instance) {
      this.instance = new Rooms();
    }
    return this.instance;
  }

  constructor() {
    super({shared: true});
  }

  protected basePath(): string {
    return "rooms";
  }

  async loadListItems(): Promise<void> {
    throw new Error("Not supported. We should never try to list all rooms.")
  }

  protected deserializeItem(value: any): Room {
    return JSON_OBJECT.deserializeObject(value, Room);
  }

  protected serializeItem(item: Room): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Room, item2: Room): number {
    return 0;
  }
}

@JsonObject()
export class RoomJoin extends BaseListItem {

  static createNew(roomId: string): RoomJoin {
    return new RoomJoin(roomId, getMemberAuth().getMemberId(), Date.now());
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class RoomJoins extends BaseListItemsLoader<RoomJoin> {

  private static instance: RoomJoins;

  static getInstance(): RoomJoins {
    if (!this.instance) {
      this.instance = new RoomJoins();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "room_joins";
  }

  protected deserializeItem(value: any): RoomJoin {
    return JSON_OBJECT.deserializeObject(value, RoomJoin);
  }

  protected serializeItem(item: RoomJoin): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: RoomJoin, item2: RoomJoin): number {
    return item2.created - item1.created;
  }
}

@JsonObject()
export class RoomGame extends BaseListItem {

  private static readonly DEFAULT = new Chess().pgn();

  // private static readonly DEFAULT = "[Event \"?\"]\n" +
  //   "[Site \"?\"]\n" +
  //   "[Date \"????.??.??\"]\n" +
  //   "[Round \"?\"]\n" +
  //   "[White \"?\"]\n" +
  //   "[Black \"?\"]\n" +
  //   "[Result \"*\"]\n" +
  //   "[SetUp \"1\"]\n" +
  //   "[FEN \"r1bqkb1r/pppp1ppp/2n2n2/3Q4/2B1P3/8/PB3PPP/RN2K1NR w KQkq - 0 1\"]\n" +
  //   "[Link \"https://www.chess.com/blog/Win_Like_McEntee/1-move-checkmate-puzzles\"]\n" +
  //   "\n" +
  //   "*";

  @JsonProperty()
  readonly pgn: string;

  static createNew(roomId: string, pgn: string = RoomGame.DEFAULT): RoomGame {
    return new RoomGame(roomId, getMemberAuth().getMemberId(), Date.now(), pgn);
  }

  constructor(id: string, creator: string, created: number, pgn: string) {
    super(id, creator, created);
    this.pgn = pgn;
  }
}

export class RoomGames extends BaseListItemsLoader<RoomGame> {

  private static instance: RoomGames;

  static getInstance(): RoomGames {
    if (!this.instance) {
      this.instance = new RoomGames();
    }
    return this.instance;
  }

  constructor() {
    super({shared: true});
  }

  protected basePath(): string {
    return "room_games";
  }

  protected deserializeItem(value: any): RoomGame {
    return JSON_OBJECT.deserializeObject(value, RoomGame);
  }

  protected serializeItem(item: RoomGame): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: RoomGame, item2: RoomGame): number {
    return item2.created - item1.created;
  }
}

@JsonObject()
export class Score extends BaseListItem {

  static createNew(gameId: string, level: string, points: number, duration: number) {
    const memberId = getMemberAuth().getMemberId();
    const now = new Date();
    return new Score(uuid(), memberId, now.getTime(), gameId, level, DateUtil.toDatestamp(now), points, duration);
  }

  @JsonObject()
  readonly scoreId;
  @JsonProperty()
  readonly gameId: string;
  @JsonProperty()
  readonly level: string;
  @JsonProperty()
  readonly ds: string;
  @JsonProperty()
  readonly points: number;
  @JsonProperty()
  readonly duration: number;

  constructor(scoreId: string, creator: string, created: number, gameId: string, level: string, ds: string, points: number, duration: number) {
    super(scoreId, creator, created);
    this.scoreId = scoreId;
    this.gameId = gameId;
    this.level = level;
    this.ds = ds;
    this.points = points;
    this.duration = duration;
  }
}

export function ScoreToString(score: Score) {
  return ScoreTimeToString(score.created);
}

export function ScoreTimeToString(time: number) {
  return new Date(time).toLocaleString("en-us", {
    dateStyle: "medium",
    timeStyle: "short"
  })
}

export class Scores extends BaseListItemsLoader<Score> {

  private static instance;

  static getInstance(): Scores {
    if (!this.instance) {
      this.instance = new Scores();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "scores";
  }

  protected deserializeItem(value: any): Score {
    return JSON_OBJECT.deserializeObject(value, Score);
  }

  protected serializeItem(item: Score): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Score, item2: Score): number {
    // Reverse chronological
    return item2.created - item1.created;
  }
}

export type BestMoveResponse = {
  result: {
    bestmove: string,
  },
}
