import React from "react";
import {FooterTabFragment} from "../../shared/FooterTabFragment";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {Action} from "../../shared/types";
import {NewGameHelper, renderGameButton} from "./EditGameHelper";
import {ChessBackground} from "./ChessBackground";


export class HomeFragment extends FooterTabFragment {

  renderContent(): React.ReactElement {
    return <ChessBackground style={{flexGrow: 1, alignItems: "center", justifyContent:"center"}}>
      <StyledBoxColumn>
        {renderGameButton(new Action("Create game", () => new NewGameHelper(this.props.path).createNewGame()))}
        {renderGameButton(new Action("Current games", () => this.props.path.navigate("/games")))}
        {renderGameButton(new Action("Find friends", () => this.props.path.navigate("/people")))}
      </StyledBoxColumn>
    </ChessBackground>;
  }
}