import {
  FullscreenDialogWithTitleFragment,
  FullscreenDialogWithTitleFragmentProps,
  FullscreenDialogWithTitleFragmentState
} from "../../shared/FullscreenDialogWithTitleFragment";
import {Box, Button, ButtonBase, Grid, SvgIcon, Typography} from "@mui/material";
import {StyledAvatar, StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "../../shared/StyledComponents";
import React, {ReactElement} from "react";
import {BORDER_RADIUS, DIVIDER, DW_XS, PD_LG, PD_SM, PD_XLG, PD_XSM, PD_XXLG, SZ_LG, SZ_SM} from "../../shared/dimens";
import {BaseApp, DIALOG_FLAG_ANIM_SLIDE, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {Action, ListItemChange, OnListItemsListener, UserDisplayName} from "../../shared/types";
import {
  AddOutlined,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  DoubleArrowOutlined,
  DownloadOutlined,
  KeyboardDoubleArrowLeftOutlined,
  OutlinedFlagOutlined,
  SettingsOutlined
} from "@mui/icons-material";
import {App, COLOR_BG1} from "../App";
import {
  CUSTOM_COLORS,
  CUSTOM_PIECES,
  DifficultyMetadata,
  Opponent,
  Piece,
  Room,
  RoomGame,
  RoomGames,
  Rooms
} from "../types";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../../shared/BaseFragment";
import {getMemberAuth} from "../../shared/auth";
import {CustomDialogContent} from "../../shared/Dialogs";
import Confetti from "react-confetti";
import {NewPageHelper} from "./EditGameHelper";
import {Chessboard} from "react-chessboard";
import {ChessBackground} from "./ChessBackground";
import {Chess, Move, Square} from "chess.js";
import {colorBlue, colorHighlightAlt, colorOrange, colorRed, colorYellow, lightGray, white} from "../../shared/colors";
import {DownloadHelper} from "./DownloadHelper";
import html2canvas from "html2canvas";
import {SettingsHelper} from "./SettingsHelper";
import {Member} from "../../shared/entities";
import {Api} from "../api";

function ViewRoomCodeView(props: { room: Room, onDismiss: () => void }): ReactElement {
  return <CustomDialogContent
    style={{minWidth: DW_XS, width: null}}
    title="Your room code"
    customView={
      <StyledBoxColumn>
        <Typography
          style={{fontSize: "400%", fontWeight: "bold", textAlign: "center"}}>
          {props.room.id}
        </Typography>
        {props.room.joinedBy
          ? <Typography style={{textAlign: "center"}}>Ask the other player to enter this code to join.</Typography>
          : null}
        <Button
          style={{
            fontSize: "150%",
            fontFamily: "Gabarito, sans-serif",
            paddingLeft: PD_XXLG,
            paddingRight: PD_XXLG
          }}
          variant="contained"
          onClick={() => props.onDismiss()}>
          Okay!
        </Button>
      </StyledBoxColumn>
    }/>;
}

function CompletedGameView(props: { room: Room, loser: string }): ReactElement {
  const didLose = (props.room.amIPlayingWhite() && props.loser === "w") || (!props.room.amIPlayingWhite() && props.loser === "b");
  return <CustomDialogContent
    style={{width: DW_XS}}
    title={didLose ? "Uh oh..." : "Congratulations!"}
    customView={
      <StyledBoxColumn style={{alignItems: "center", padding: PD_XLG, gap: PD_XLG}}>
        <img src={didLose ? "/images/thumbs_down.png" : "/images/thumbs_up.png"} style={{width: 240}}/>
        <Typography
          style={{fontSize: "100%", fontWeight: "bold", textAlign: "center"}}>
          {didLose ? "You lose." : "You win."}
        </Typography>
      </StyledBoxColumn>
    }/>;
}

type GameBoardFragmentProps = FullscreenDialogWithTitleFragmentProps & {
  initialRoom: Room,
  onRoomDidReset: (room: Room) => void,
}

type GameBoardFragmentState = FullscreenDialogWithTitleFragmentState & {
  room: Room,
  turnIndex: number,
  showCompleted?: string,
  boardSize: number,
  game?: Chess,
  fetchingComputerMove?: boolean,
  navigatingBackIndex: number,
}

function GameBoard(props: { room: Room, game: Chess, setGame: (game: Chess) => void, navigatingBackIndex: number }) {
  function makeAMove(move: Move): boolean {
    const gameCopy = Object.assign(new Chess(), props.game);
    try {
      gameCopy.move(move);
      props.setGame(gameCopy);
      return true;
    } catch (e) {
      return false;
    }
  }

  function isDraggablePiece(args: { piece: Piece, sourceSquare: Square }): boolean {
    // do not pick up pieces if the game is over
    if (props.game.isGameOver()) {
      return false;
    }

    // only pick up pieces for the side to move
    if ((props.game.turn() === 'w' && args.piece.search(/^b/) !== -1) ||
      (props.game.turn() === 'b' && args.piece.search(/^w/) !== -1)) {
      return false;
    }

    return true;
  }

  function onDrop(sourceSquare, targetSquare) {
    return makeAMove({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q", // always promote to a queen for example simplicity
    } as Move);
  }

  const settings = props.room.settings;
  const customColors = CUSTOM_COLORS.findMetadata(settings.colorsSet);
  let game = props.game;
  if (props.navigatingBackIndex > 0) {
    const moves = props.game.history();
    game = new Chess();
    for (const move of moves.slice(0, moves.length - props.navigatingBackIndex)) {
      game.move(move);
    }
  }
  const arePiecesDraggable =
    !props.room.completedAt
    && !props.navigatingBackIndex
    && ((props.game.turn() === "w" && props.room.amIPlayingWhite()) || (props.game.turn() === "b" && !props.room.amIPlayingWhite()));
  return <Chessboard
    id={"game-chessboard"}
    position={game.fen()}
    boardOrientation={props.room.amIPlayingWhite() ? "white" : "black"}
    arePiecesDraggable={arePiecesDraggable}
    isDraggablePiece={isDraggablePiece}
    onPieceDrop={onDrop}
    customLightSquareStyle={{backgroundColor: customColors.light}}
    customDarkSquareStyle={{backgroundColor: customColors.dark}}
    customPieces={CUSTOM_PIECES.findMetadata(settings.piecesSet)}
    customBoardStyle={{borderRadius: BORDER_RADIUS}}/>;
}

function TurnIndicator() {
  return <StyledBoxRow style={{
    background: colorYellow,
    borderRadius: BORDER_RADIUS,
    paddingLeft: PD_SM,
    paddingRight: PD_SM,
    gap: 0,
    alignItems: "center"
  }}>
    <KeyboardDoubleArrowLeftOutlined/>
  </StyledBoxRow>;
}

class GameBoardFragment extends FullscreenDialogWithTitleFragment<GameBoardFragmentProps, GameBoardFragmentState> implements OnListItemsListener<Room>, OnListItemsListener<RoomGame> {

  protected onCreateState(): GameBoardFragmentState {
    return {
      ...super.onCreateState(),
      room: this.props.initialRoom,
      turnIndex: 0,
      boardSize: 0,
      navigatingBackIndex: 0,
    };
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const game = new Chess();
    game.loadPgn((await RoomGames.getInstance().getOrLoadItem(this.props.initialRoom.id))?.pgn || "");
    this.setState({
      game: game,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    RoomGames.getInstance().registerChildObserver(this.state.room.id, this);
    Rooms.getInstance().registerChildObserver(this.state.room.id, this);
  }

  componentWillUnmount() {
    RoomGames.getInstance().unregisterChildObserver(this.state.room.id, this);
    Rooms.getInstance().unregisterChildObserver(this.state.room.id, this);
  }

  onItemChanged(item: Room | RoomGame, change: ListItemChange) {
    if (item instanceof Room) {
      if (item.id !== this.state.room.id) {
        return;
      }
      this.setState({
        room: item,
      });
    } else if (item instanceof RoomGame) {
      const game = new Chess();
      game.loadPgn(item.pgn);
      this.setState({game: game});
    }
  }

  componentDidUpdate(prevProps: Readonly<GameBoardFragmentProps>, prevState: Readonly<GameBoardFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.room.layout?.id !== this.state.room.layout?.id) {
      this.onReset();
    } else {
      if (prevState.game !== this.state.game) {
        const game = this.state.game;
        const room = this.state.room;
        const playingWhite = room.amIPlayingWhite();
        if (room.opponent === Opponent.COMPUTER && ((!playingWhite && game.turn() === "w") || (playingWhite && game.turn() === "b"))) {
          if (!this.state.fetchingComputerMove) {
            this.setState({
              fetchingComputerMove: true,
            });
            try {
              this.fetchComputerMove();
            } catch (e) {
              App.CONTEXT.showToast("Oops. Something went wrong. Please refresh this window or try again later.");
            }
          }
        }
      }
      if (prevState.room.completedAt !== this.state.room.completedAt && this.state.room.completedAt) {
        this.setState({
          showCompleted: App.CONTEXT.showDialog(
            {flags: DIALOG_FLAG_ANIM_SLIDE},
            () => <CompletedGameView room={this.state.room}
                                     loser={this.state.room.resignedBy || this.state.game.turn()}/>)
        });
        setTimeout(() => this.setState({showCompleted: null}), 5000);
      }
      if (Boolean(prevState.showCompleted) && !Boolean(this.state.showCompleted)) {
        this.hideDialog(prevState.showCompleted);
      }
    }
  }

  private async fetchComputerMove(): Promise<void> {
    const bestMove = await Api.getInstance().getBestMove(this.state.game, this.state.room.difficultyMetadata?.elo);
    const gameCopy = Object.assign(new Chess(), this.state.game);
    try {
      gameCopy.move(bestMove.result.bestmove);
      this.updateGame(gameCopy);
      this.setState({
        fetchingComputerMove: false,
      });
    } catch (e) {
      App.CONTEXT.showToast("Oops. Something went wrong! Please try again later.");
    }
  }

  protected async onReset() {
    this.setState({
      game: null,
    });
    await RoomGames.getInstance().addListItem(RoomGame.createNew(this.state.room.id));
    this.props.onRoomDidReset(this.state.room);
  }

  getCustomTitle(): ReactElement {
    return <StyledBoxRow>
      <img src={"/images/logotype.png"} style={{height: 48}}/>
    </StyledBoxRow>;
  }

  renderContent() {
    return <ChessBackground style={{flexGrow: 1, background: COLOR_BG1}}>
      {/*{this.renderGameToolbar()}*/}
      <Box style={{display: "flex", flexDirection: "column", flexGrow: 1, position: "relative"}}>
        {this.renderBoard()}
        {Boolean(this.state.showCompleted)
          ? <Confetti
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: "100%",
              zIndex: 1000
            }}
            run/>
          : null}
      </Box>
    </ChessBackground>;
  }

  private _getCapturedPiecesForColor(color: string) {
    const captured = {'p': 0, 'n': 0, 'b': 0, 'r': 0, 'q': 0, 'k': 0}
    for (const move of this.state.game.history({verbose: true})) {
      if (move.captured && move.color !== color) {
        captured[move.captured]++;
      }
    }
    return captured;
  }

  getCapturedWhitePieces() {
    return this._getCapturedPiecesForColor("w")
  }

  getCapturedBlackPieces() {
    return this._getCapturedPiecesForColor("b")
  }

  private renderCapturedPieces(capturedPieces: any, color: "w" | "b", piece: string) {
    const n = capturedPieces[piece] as number;
    if (n) {
      return <StyledBoxRow style={{alignItems: "center", gap: 0}}>
        <img src={"/assets/pieces/" + this.state.room.settings.piecesSet + "/" + color + piece.toUpperCase() + ".png"}
             style={{width: 24, height: 24}}/>
        <Typography variant="caption">{n + ""}</Typography>
      </StyledBoxRow>;
    }
    return null;
  }

  private getBoardBorderColor(game: Chess): string {
    if (game.isCheckmate()) {
      return colorRed;
    }
    if (game.isCheck()) {
      return colorOrange;
    }
    return null;
  }

  private getOtherMember(): Member {
    const room = this.state.room;
    if (room.opponent === Opponent.COMPUTER) {
      const gd = DifficultyMetadata.VALUES.find(value => value.name === room.difficulty);
      return gd.member();
    }
    return room.creator === getMemberAuth().getMemberId() ? room.joiner : room.member;
  }

  private updateGame(game: Chess): void {
    const room = this.state.room;
    this.setState({game: game});
    RoomGames.getInstance().addListItem(RoomGame.createNew(this.state.room.id, game.pgn()));
    if (game.isCheckmate() && !room.completedAt) {
      const room = this.state.room.clone<Room>(Room);
      room.completedAt = Date.now();
      Rooms.getInstance().addListItem(room);
    }
  }

  private renderBoard() {
    const me = getMemberAuth().member;
    const room = this.state.room;
    const game = this.state.game;
    if (!room || !game) {
      return null;
    }
    const boardBorderColor = this.getBoardBorderColor(game);
    const playingWhite = room.amIPlayingWhite();
    const capturedPieces = playingWhite ? this.getCapturedBlackPieces() : this.getCapturedWhitePieces();
    const otherMember = this.getOtherMember();
    const otherMemberCapturedPieces = !playingWhite ? this.getCapturedBlackPieces() : this.getCapturedWhitePieces();
    return <StyledContainer size="xxlg" style={{flexGrow: 1}}>
      <Grid container spacing={1} style={{flexGrow: 1}}>
        <Grid item xs={12} md={7} sm={10}>
          <div style={{position: "relative"}}>
            <GameBoard
              room={this.state.room}
              game={game}
              setGame={game => this.updateGame(game)}
              navigatingBackIndex={this.state.navigatingBackIndex}/>
            {boardBorderColor
              ? <div style={{
                pointerEvents: "none",
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                border: "4px solid " + boardBorderColor
              }}/>
              : null}
          </div>
        </Grid>
        <Grid item xs={12} md={5} sm={10}>
          <StyledBoxColumn
            style={{
              background: "white",
              height: "100%",
              gap: 0,
              minHeight: 640,
              borderRadius: BORDER_RADIUS,
              border: DIVIDER
            }}>
            <StyledBoxRow
              style={{alignItems: "center", padding: PD_SM, height: SZ_SM, borderBottom: DIVIDER}}>
              <StyledAvatar member={otherMember}/>
              <StyledBoxColumn style={{flexGrow: 1, gap: 0}}>
                <StyledBoxRow style={{alignItems: "center"}}>
                  <Typography>
                    <b>{otherMember
                      ? UserDisplayName(otherMember?.user)
                      : "Waiting for a player to join..."}
                    </b>
                  </Typography>
                  {(!playingWhite && game.turn() === "w") || (playingWhite && game.turn() === "b")
                    ? <TurnIndicator/>
                    : null}
                  <StyledSpan/>
                </StyledBoxRow>
                <StyledBoxRow>
                  {this.renderCapturedPieces(otherMemberCapturedPieces, playingWhite ? "w" : "b", "p")}
                  {this.renderCapturedPieces(otherMemberCapturedPieces, playingWhite ? "w" : "b", "n")}
                  {this.renderCapturedPieces(otherMemberCapturedPieces, playingWhite ? "w" : "b", "b")}
                  {this.renderCapturedPieces(otherMemberCapturedPieces, playingWhite ? "w" : "b", "r")}
                  {this.renderCapturedPieces(otherMemberCapturedPieces, playingWhite ? "w" : "b", "q")}
                </StyledBoxRow>
              </StyledBoxColumn>
            </StyledBoxRow>
            <Box style={{display: "flex", borderBottom: DIVIDER}}>
              {this.renderToolbarButtons()}
            </Box>
            <StyledBoxColumn style={{overflowY: "scroll", flexGrow: 1}}>
              {this.renderHistory()}
            </StyledBoxColumn>
            <StyledBoxRow
              style={{alignItems: "center", padding: PD_SM, height: SZ_SM, borderTop: DIVIDER}}>
              <StyledAvatar member={me}/>
              <StyledBoxColumn style={{flexGrow: 1, gap: 0}}>
                <StyledBoxRow style={{alignItems: "center"}}>
                  <Typography>
                    <b>
                      {UserDisplayName(me.user)}
                    </b>
                  </Typography>
                  {(playingWhite && game.turn() === "w") || (!playingWhite && game.turn() === "b")
                    ? <TurnIndicator/>
                    : null}
                  <StyledSpan/>
                </StyledBoxRow>
                <StyledBoxRow>
                  {this.renderCapturedPieces(capturedPieces, playingWhite ? "b" : "w", "p")}
                  {this.renderCapturedPieces(capturedPieces, playingWhite ? "b" : "w", "n")}
                  {this.renderCapturedPieces(capturedPieces, playingWhite ? "b" : "w", "b")}
                  {this.renderCapturedPieces(capturedPieces, playingWhite ? "b" : "w", "r")}
                  {this.renderCapturedPieces(capturedPieces, playingWhite ? "b" : "w", "q")}
                </StyledBoxRow>
              </StyledBoxColumn>
            </StyledBoxRow>
            <StyledBoxRow
              style={{alignItems: "center", padding: PD_SM, height: SZ_SM, borderTop: DIVIDER}}>
              <StyledSpan/>
              <Button startIcon={<DownloadOutlined/>} onClick={() => {
                const el: HTMLElement = window.document.querySelector('[data-boardid="game-chessboard"]');
                (async function () {
                  const imageUrl = (await html2canvas(el)).toDataURL("image/png");
                  DownloadHelper.showDialog(game, imageUrl);
                })();
              }}>Download</Button>
              <Button
                startIcon={<SettingsOutlined/>}
                onClick={() => SettingsHelper.showDialog(this.state.room.settings, settings => {
                  Rooms.getInstance().addListItem(this.state.room.clone(Room).updateSelf(null, settings));
                })}>Settings</Button>
              <Button
                disabled={Boolean(this.state.room.completedAt)}
                startIcon={<OutlinedFlagOutlined/>}
                onClick={() => this.onResign()}
              >Resign</Button>
              <StyledSpan/>
            </StyledBoxRow>
          </StyledBoxColumn>
        </Grid>
      </Grid>
    </StyledContainer>;
  }

  private onResign() {
    BaseApp.CONTEXT.showTextDialog("Resign", "Are you sure you want to resign?", new Action("Yes, resign", () => {
      BaseApp.CONTEXT.hideDialog();
      const room = this.state.room.clone<Room>(Room);
      room.resignedBy = this.state.room.amIPlayingWhite() ? "w" : "b";
      room.completedAt = Date.now();
      Rooms.getInstance().addListItem(room);
    }));
  }

  private renderHistory(): ReactElement {
    const history = this.state.game.history();
    const items: ReactElement[] = [];
    for (let i = 0; i < history.length; i += 2) {
      const moveNum = Math.floor(i / 2) + 1;
      const background = Math.floor(i / 2) % 2 === 0 ? lightGray : null;
      items.push(<Grid item xs={2}><Typography
        style={{padding: PD_SM, background: background}}>{moveNum + "."}</Typography></Grid>);
      items.push(this.renderMove(history, i, background));
      if (i < history.length - 1) {
        items.push(this.renderMove(history, i + 1, background));
      }
    }
    return <Grid container>
      {items}
    </Grid>
  }

  private renderMove(history: string[], index: number, background: string): ReactElement {
    const move = history[index];
    return <Grid item xs={5}><Typography
      style={{
        padding: PD_SM,
        fontWeight: "bold",
        background: index === (history.length - this.state.navigatingBackIndex - 1) ? colorHighlightAlt : background
      }}>{move}</Typography></Grid>;
  }

  private renderToolbarButtons() {
    const historyLength = this.state.game.history()?.length || 0;
    return <>
      <ButtonBase
        onClick={() => {
          App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
            <ViewRoomCodeView
              room={this.state.room}
              onDismiss={() => {
                App.CONTEXT.hideDialog();
              }}/>
          );
        }}>
        <StyledBoxColumn style={{
          height: SZ_SM,
          flexShrink: 0,
          width: SZ_LG,
          gap: PD_XSM,
          padding: PD_SM,
          background: colorBlue,
          color: white,
          alignItems: "center",
          justifyContent: "center"
        }}>
          <Typography variant="h6">{this.state.room.id}</Typography>
          {/*<Typography variant="h6">{"00:39"}</Typography>*/}
        </StyledBoxColumn>
      </ButtonBase>
      <StyledBoxRow style={{flexGrow: 1, alignItems: "center", padding: PD_SM}}>
        {this.renderToolbarActionButton(() => {
          if (!this.state.room.completedAt) {
            this.onResign();
          } else {
            new NewPageHelper(this.props.path, this.state.room).updateRoom();
          }
        }, AddOutlined)}
        {this.renderToolbarActionButton(() => this.setState({navigatingBackIndex: (this.state.navigatingBackIndex || 0) + 1}), ArrowBackIosOutlined, this.state.navigatingBackIndex >= historyLength)}
        {this.renderToolbarActionButton(() => this.setState({navigatingBackIndex: (this.state.navigatingBackIndex || 0) - 1}), ArrowForwardIosOutlined, this.state.navigatingBackIndex <= 0)}
        {this.renderToolbarActionButton(() => this.setState({navigatingBackIndex: 0}), DoubleArrowOutlined, this.state.navigatingBackIndex <= 0)}
      </StyledBoxRow>
    </>
  }

  private renderToolbarActionButton(onClick: () => void, IconType: typeof SvgIcon, disabled?: boolean) {
    return <Button
      disabled={disabled}
      style={{width: 0, flexGrow: 1, height: SZ_SM}}
      onClick={onClick}>
      <IconType style={{width: 32, height: 32}}/>
    </Button>;
  }

  private onHelp() {
    BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE}, props => {
      return <StyledBoxColumn style={{padding: PD_LG}}>
        <Typography variant="h5">How to play Chess</Typography>
        <Typography>TO BE DONE!</Typography>
      </StyledBoxColumn>;
    });
  }
}


export type GameFragmentProps = BaseFragmentProps & {
  initialRoom: Room,
}

type GameFragmentState = BaseFragmentState & {
  room: Room,
}

export class GameFragment extends BaseFragment<GameFragmentProps, GameFragmentState> {

  protected onCreateState(): GameFragmentState {
    return {
      ...super.onCreateState(),
      room: this.props.initialRoom,
    };
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <GameBoardFragment
      path={this.props.path}
      initialRoom={this.state.room}
      onRoomDidReset={(room: Room) => this.setState({room: room})}/>;
  }
}