import {BoxProps} from "@mui/material";
import {StyledBoxColumnWithBackgroundCoverImage} from "../../shared/StyledComponents";

export function ChessBackground(props: BoxProps) {
  return <StyledBoxColumnWithBackgroundCoverImage
    backgroundCoverImageOpacity={0.5}
    backgroundCoverImage={"/images/cover.png"}
    style={{flexGrow: 1}}
    {...props}>
    {props.children}
  </StyledBoxColumnWithBackgroundCoverImage>
}